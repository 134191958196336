import React from "react";
import {Link} from "react-router-dom";

class Footer extends React.Component {
    render() {
        return(
            <div className="footer">
                <div className="copyright"><i className="far fa-copyright"></i> 2020 LEWDNATIC</div>
                <div className="links">
                    <Link to="/terms">Terms</Link>
                    <a href="mailto:contact@lewdnatic.com">Contact</a> 
                </div>
            </div>
        );
    }
}

export default Footer;
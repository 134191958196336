import React from "react";
import {Link} from "react-router-dom";

class Header extends React.Component {
    render() {
        return(
            <div className="header">
                <div className="logo">
                    <Link to="/"><img src="/images/logo.png" alt="" /></Link>
                </div>
                <div className="social">
                    <a href="https://twitter.com/lewdnatic" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                    <a href="https://www.youtube.com/channel/UCtRvtAClfTbbc0xq4rLhMog" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                    <a href="https://www.patreon.com/lewdnatic" target="_blank" rel="noopener noreferrer"><i className="fab fa-patreon"></i></a>
                </div>
            </div>
        );
    }
}

export default Header;
import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";

class Gallery extends React.Component {

    constructor() {
        super();
        this.state = {table: []};
    }

    componentDidMount() {
        document.title = "LEWDNATIC - I Love Hentai";
        this.load();
    }

    load() {
        var self = this;
        axios.get("/scripts/loadgallery.php").then(function (response) {
            self.setState({table: response.data});
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        return(
            <div className="gallery">

                {this.state.table.map(row =>

                    <div className="gallery-entry">
                        <Link to={"/animation/" + row.url}>
                            <img src={"/images/thumbnails/" + row.thumbnail} alt="" />
                            <div className="gallery-entry-overlay"></div>
                            <div className="gallery-entry-title"><i className="fas fa-play"></i></div>
                        </Link>
                    </div>
                    
                )}

            </div>
        );
    }
}

export default Gallery;
import React from "react";
import {
    Link
} from "react-router-dom";
import axios from "axios";

class Lightbox extends React.Component {

    constructor() {
        super();
        this.state = {width: 1280, height: 720, data: null};
    }

    componentDidMount() {
        document.body.style.overflow = "hidden";
        this.resize();
        this.load();

        window.addEventListener("resize", this.resize.bind(this));
    }

    componentWillUnmount() {
        document.body.style.overflow = "auto";
        document.title = "LEWDNATIC - I Love Hentai";

        window.removeEventListener("resize", this.resize.bind(this));
    }

    load() {
        var self = this;
        var url = this.props.match.params.url;
        axios.get("/scripts/loadvideo.php?v=" + url).then(function (response) {
            if (response.data && response.data.title) {
                self.setState({data: response.data});
                document.title = "LEWDNATIC - " + response.data.title;
            }
        });
    }

    resize() {
        const maxw = 1280;
        const maxh = 720;
        const marginw = 15;
        const marginh = 45;
        var w = document.body.clientWidth - 2 * marginw;
        var h = document.body.clientHeight - 2 * marginh;
        var r = w / h;

        if (r <= 16/9) {
            if (w > maxw) w = maxw;
            this.setState({width: w, height: w / 16*9});
        } else {
            if (h > maxh) h = maxh;
            this.setState({width: h / 9*16, height: h});
        }
    }

    render() {
        let source;
        if (this.state.data != null) {
            source = <source src={"/videos/" + this.state.data.video} type="video/mp4" />;
        }

        return(
            <div className="modal">
                <div className="modal-content">
                    <video id="player" width={this.state.width} height={this.state.height} autoPlay controls loop>
                        {source}
                    </video>
                </div>
                <Link to="/" className="modal-overlay-wrapper">
                    <div className="modal-overlay"></div>
                    <div className="modal-close"><i className="fas fa-times"></i></div>
                </Link>
            </div>
        );
    }
}

export default Lightbox;
import React from "react";

class Terms extends React.Component {

    componentDidMount() {
        document.title = "LEWDNATIC - Terms";
    }

    render() {
        return(
            <div className="terms">
                <h2>Terms of Service</h2>
                <p>
                    Welcome to LEWDNATIC!
                    <br />
                    <br />
                    These Terms of Service govern your use of LEWDNATIC and provide information about the LEWDNATIC Service, outlined below. When you use LEWDNATIC, you agree to these terms.
                </p>
                <h3>Age Restriction</h3>
                <p>
                    You affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the Websites from. If you are under 18 or the applicable age of majority, you are not permitted to use the Website. You also represent that the jurisdiction from which you access the Websites does not prohibit the receiving or viewing of sexually explicit content.
                </p>
                <h3>Content Disclaimer</h3>
                <p>
                    All characters depicted on this website are fictional and at least 18 years of age.
                </p>
                <h3>Cookies Policy</h3>
                <p>
                    This website uses cookies. By using the Service, you consent to the use of cookies.
                </p>
                <h4>What are cookies</h4>
                <p>
                    Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third­-party to recognize you and make your next visit easier and the Service more useful to you.
                </p>
                <h4>How we use cookies</h4>
                <p>
                    When you use and access the Service, we may place a number of cookies in your web browser.
                    <br />
                    <br />
                    We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences.
                </p>
                <h4>What are your choices regarding cookies</h4>
                <p>
                    If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.
                    <br />
                    <br />
                    Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
                </p>
            </div>
        );
    }
}

export default Terms;
import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from "./Header.js";
import Footer from "./Footer.js";
import Gallery from "./Gallery.js";
import Lightbox from "./Lightbox.js";
import Terms from "./Terms.js";

export default function App() {
  return (

	<Router>
		<div className="container">
			<Header />
			<div className="main">
				<Switch>
					<Route path="/terms" component={Terms} />
					<Route path="/" component={Gallery} />
				</Switch>
			</div>
			<Footer />
		</div>
		<Route path="/animation/:url" component={Lightbox} />
	</Router>
    
  );
}
